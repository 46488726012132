import { useEffect, useRef } from 'react';

const useIntersectionObserver = (
  callback: (isVisible: boolean) => void,
  options = {
    root: null,
    rootMargin: '0px',
    threshold: 0
  }
) => {
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback(true);
        } else {
          callback(false);
        }
      });
    }, options);

    const currentRef = ref.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [callback, options]);

  return ref;
};

export default useIntersectionObserver;
