import { useCallback, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { CheckSimulationStatusResponse } from '@core/api';
import { useTranslation } from '@core/translation';
import useLogin from '@features/login';
import { storage } from '@features/onboarding-2';

import useSimulateQuote from '../../hooks/useSimulateQuote';
import CampaignCodeSection from '../CampaignCodeSection';
import { PriceSuggestionBanner, PriceSuggestionBlock } from './components';
import styles from './PriceSuggestionSection.module.css';

const PriceSuggestionSection = () => {
  const { isLoading, login, isAuthenticated } = useLogin();
  const router = useRouter();
  const et = useTranslation('ERROR');
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());

  const [simulationResult, setSimulationResult] = useState<
    Partial<CheckSimulationStatusResponse['result']>
  >({
    monthlyPremium: storage().onboarding?.monthlyPremium,
    isValidSimulation: true
  });

  const { handleSimulateQuote } = useSimulateQuote();

  useEffect(() => {
    if (isAuthenticated) {
      router.push({
        pathname: '/onboarding/summary'
      });
    }
  }, [isAuthenticated, router]);

  const saveStartDate = (date: Date) => {
    storage().saveOnboardingStore({
      ...storage().onboarding,
      policyStartDate: date.toISOString()
    });
  };

  const handleAuthClick = () => {
    saveStartDate(selectedStartDate);
    login();
  };

  const saveToStorage = useCallback(
    (
      data: Partial<CheckSimulationStatusResponse['result']>,
      discountCode: string,
      campaignCode: string
    ) => {
      storage().saveOnboardingStore({
        ...storage().onboarding,
        ...data,
        discountCode,
        campaignCode
      });
    },
    []
  );

  const handleSimulateQuoteWithCode = useCallback(
    async (discountCode: string, campaignCode: string) => {
      const data = await handleSimulateQuote({
        ...storage().onboarding,
        discountCode,
        campaignCode
      });

      if (data) {
        setSimulationResult(data);
        saveToStorage(data, discountCode, campaignCode);
      }
    },
    [handleSimulateQuote, saveToStorage]
  );

  const unexpectedError = (
    <div className={styles.errorContainer}>
      <p>{et('UNEXPECTED_ERROR')}</p>
    </div>
  );

  const [isPriceSuggestionBlockVisible, setIsPriceSuggestionBlockVisible] =
    useState(true);

  return (
    <>
      {!isPriceSuggestionBlockVisible && simulationResult.monthlyPremium && (
        <PriceSuggestionBanner
          onAuthClick={handleAuthClick}
          isLoading={isLoading}
          monthlyPremium={simulationResult.monthlyPremium}
          discountMonthlyPremium={storage().onboarding?.discountMonthlyPremium}
          selectedStartDate={selectedStartDate}
          onDateChange={setSelectedStartDate}
        />
      )}
      {simulationResult.monthlyPremium ? (
        <PriceSuggestionBlock
          onVisibilityChange={setIsPriceSuggestionBlockVisible}
          monthlyPremium={simulationResult.monthlyPremium}
          discountMonthlyPremium={storage().onboarding?.discountMonthlyPremium}
          selectedStartDate={selectedStartDate}
          onDateChange={setSelectedStartDate}
          onAuthClick={handleAuthClick}
          isLoading={isLoading}
        />
      ) : (
        unexpectedError
      )}
      <CampaignCodeSection
        simulateQuoteWithCode={handleSimulateQuoteWithCode}
      />
    </>
  );
};

export default PriceSuggestionSection;
