import {
  Text,
  usePriceSuggestionPageTranslation
} from '@features/onboarding-2';

interface YourPriceProps {
  price: number;
}

import styles from './PriceSuggestionBannerYourPrice.module.css';
const PriceSuggestionBannerYourPrice = ({ price }: YourPriceProps) => {
  const t = usePriceSuggestionPageTranslation();
  return (
    <div className={styles.priceContainer}>
      <Text type="normal-big" className={styles.yourPrice}>
        {t('YOUR_PRICE')}
      </Text>
      <h4 className={styles.price}>{price}</h4>
      <Text type="heavy-big">kr</Text>
      <Text type="normal-small">{t('PAYMENT_FREQUENCY')}</Text>
    </div>
  );
};

export default PriceSuggestionBannerYourPrice;
