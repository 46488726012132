import { forwardRef, PropsWithChildren } from 'react';

import { cn } from '@lib/utils';

import styles from './Block.module.css';

type BlockProps = PropsWithChildren & {
  className?: string;
  variant?: 'primary' | 'fourth';
  blockInnerClassName?: string;
};

const Block = forwardRef<HTMLDivElement, BlockProps>(
  ({ children, className, blockInnerClassName, variant = 'primary' }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          styles.block,
          className,
          variant === 'primary' ? styles.primary : styles.fourth
        )}
      >
        <div className={cn(styles.blockInner, blockInnerClassName)}>
          {children}
        </div>
      </div>
    );
  }
);

export default Block;
