import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import {
  InputMessageProps,
  ProcessStep,
  ProcessStepProps,
  usePriceSuggestionLabelsTranslation
} from '@features/onboarding-2';

import { AddressFields, HomeInfoFields } from '../';
import { OnboardingFormFields } from '../../../OnboardingContainer';

type Props = {
  register: UseFormRegister<OnboardingFormFields>;
  setValue: UseFormSetValue<OnboardingFormFields>;
  invalidPostalCodeMessage?: InputMessageProps;
  validationStateStep: ProcessStepProps['state'];
  errors?: FieldErrors<OnboardingFormFields>;
  setIsSendDisabled: (value: boolean) => void;
  disabledFields?: {
    address: boolean;
    postalCode: boolean;
  };
};

// TODO: Fix duplicate code src/features/onboarding-2/pages/Onboarding/components/OnboardingForm/components/PersonalInfoFields/PersonalInfoFields.tsx
const getErrorMessage = (fieldError?: { message?: string }) =>
  fieldError?.message ? { title: fieldError.message } : undefined;

const YourHomeStep = ({
  register,
  setValue,
  invalidPostalCodeMessage,
  validationStateStep,
  setIsSendDisabled,
  errors,
  disabledFields
}: Props) => {
  const t = usePriceSuggestionLabelsTranslation();
  return (
    <ProcessStep
      step={2}
      title={t('PROCESS_STEP_YOUR_HOME')}
      state={validationStateStep}
    >
      <AddressFields
        register={register}
        invalidPostalCodeMessage={
          invalidPostalCodeMessage || getErrorMessage(errors?.postalCode)
        }
        disabledFields={disabledFields}
      />
      <HomeInfoFields
        setIsSendDisabled={setIsSendDisabled}
        setValue={setValue}
        register={register}
      />
    </ProcessStep>
  );
};

export default YourHomeStep;
