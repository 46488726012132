import { forwardRef } from 'react';

import { Icon } from '@components/index';
import { Text } from '@features/onboarding-2';
import { cn } from '@lib/utils';

import { usePriceSuggestionPageTranslation } from '../../../../../../translation';
import styles from './StartDateSelector.module.css';

type Props = {
  selectedDate: string;
  startDateContainerClassName?: string;
  selectedDateClassName?: string;
  iconClassName?: string;
};

const StartDateSelector = forwardRef<HTMLButtonElement, Props>(
  (
    {
      selectedDate,
      startDateContainerClassName,
      selectedDateClassName,
      iconClassName,
      ...rest
    },
    ref
  ) => {
    const t = usePriceSuggestionPageTranslation();

    const startDate = (
      <div
        className={cn(styles.startDateContainer, startDateContainerClassName)}
      >
        <Text type="heavy-small" className={styles.startDateText}>
          {t('START_DATE')}
        </Text>
        <Text type="heavy-big" className={selectedDateClassName}>
          {selectedDate}
        </Text>
      </div>
    );

    const calendarIcon = (
      <div>
        <Icon
          name="calendar"
          className={cn(styles.calendarIcon, iconClassName)}
        />
      </div>
    );

    const content = (
      <div className={styles.dateSelectorContent}>
        {startDate}
        {calendarIcon}
      </div>
    );

    return (
      <button ref={ref} {...rest} className={styles.dateSelector}>
        {content}
      </button>
    );
  }
);

export default StartDateSelector;
