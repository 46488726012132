import {
  Block,
  usePriceSuggestionPageTranslation
} from '@features/onboarding-2';

import { Datepicker } from '../../../../pages/PriceSuggestion';
import BankIDButton from '../BankIDButton/BankIDButton';
import { PriceSuggestionBannerYourPrice } from './components';
import styles from './PriceSuggestionBanner.module.css';

type Props = {
  onAuthClick: () => void;
  isLoading: boolean;
  monthlyPremium: number;
  discountMonthlyPremium: number;
  onDateChange: (date: Date) => void;
  selectedStartDate: Date;
};

const PriceSuggestionBanner = ({
  onAuthClick,
  isLoading,
  monthlyPremium,
  onDateChange,
  selectedStartDate
}: Props) => {
  const t = usePriceSuggestionPageTranslation();

  return (
    <Block
      variant="fourth"
      className={styles.block}
      blockInnerClassName={styles.blockInner}
    >
      <PriceSuggestionBannerYourPrice price={monthlyPremium} />
      <div className={styles.actionsContainer}>
        <Datepicker
          date={selectedStartDate}
          onDateChange={onDateChange}
          startDateContainerClassName={styles.startDateContainer}
          selectedDateClassName={styles.selectedDate}
          iconClassName={styles.calendarIcon}
          containerClassName={styles.datePickerContainer}
          calendarClassName={styles.calendar}
        />
        <BankIDButton
          onClick={onAuthClick}
          isLoading={isLoading}
          title={t('CONTINUE')}
          size="small"
          className={styles.bankIdButton}
        />
      </div>
    </Block>
  );
};

export default PriceSuggestionBanner;
