import { useEffect, useState } from 'react';

import { differenceInYears } from 'date-fns';
import { AxiosError } from 'axios';

import { useGetUserByPersonalNumber } from '@core/api';
import { logger } from '@lib/logger';
import {
  ApiResponse,
  ValidResponse as ValidUserResponse
} from '@pages/api/onboarding/user/[personalNumber]';

type UserDetails = {
  address: string;
  age: string;
  postalCode: string;
  isLoading: boolean;
  refetch: () => void;
  setAge: (age: string) => void;
  setAddress: (address: string) => void;
  setPostalCode: (postalCode: string) => void;
};

type UseUserDetailsProps<T = unknown> = {
  personalNumber: {
    value: string;
    isValid: boolean;
  };
  onError?: (error: Error | AxiosError<T>) => void;
};

const isValidUserResponse = (
  response: ApiResponse
): response is ValidUserResponse => {
  return (response as ValidUserResponse).data !== undefined;
};

export const useUserDetails = <T = unknown>({
  personalNumber,
  onError
}: UseUserDetailsProps<T>): UserDetails => {
  const [address, setAddress] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [age, setAge] = useState('');

  const handleSuccess = (response: ApiResponse) => {
    if (isValidUserResponse(response) && response.data) {
      const { details, populationRegistrationAddress } = response.data;
      const address = populationRegistrationAddress[0]?.swedishAddress;
      const birthDateString = details[0]?.birth?.birthDate;

      if (!address || !birthDateString) {
        onError?.(new Error('User details missing'));
      }

      const birthDate = new Date(birthDateString);
      const today = new Date();
      const age = differenceInYears(today, birthDate);

      setAddress(address.deliveryAddress1 || address.deliveryAddress2);
      setPostalCode(address.zipCode);
      setAge(age.toString());
    }
  };

  const handleError = (error: Error) => {
    logger.error('Error fetching user by personal number', error);
    onError?.(error);
  };

  const { refetch, isLoading } = useGetUserByPersonalNumber(
    personalNumber.value
  )({
    options: {
      enabled: false,
      onError: handleError,
      onSuccess: handleSuccess
    }
  });

  useEffect(() => {
    if (personalNumber.isValid) {
      refetch();
    }
  }, [personalNumber.isValid, refetch]);

  return {
    age,
    address,
    postalCode,
    isLoading,
    refetch,
    setAge,
    setAddress,
    setPostalCode
  };
};
