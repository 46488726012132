import { UseFormRegister } from 'react-hook-form';

import {
  InputMessageProps,
  OnboardingTextField,
  usePriceSuggestionLabelsTranslation
} from '@features/onboarding-2';

import { OnboardingFormFields } from '../../../OnboardingContainer';

type AddressFieldsProps = {
  register: UseFormRegister<OnboardingFormFields>;
  invalidPostalCodeMessage?: InputMessageProps;
  disabledFields?: {
    address: boolean;
    postalCode: boolean;
  };
};

const AddressFields = ({
  register,
  invalidPostalCodeMessage,
  disabledFields
}: AddressFieldsProps) => {
  const labelsTranslations = usePriceSuggestionLabelsTranslation();

  return (
    <>
      <OnboardingTextField
        {...register('address')}
        id="address"
        label={labelsTranslations('ADDRESS')}
        disabled={disabledFields?.address}
      />
      <OnboardingTextField
        {...register('postalCode', { required: 'PostalCode is required' })} // TODO: Should this be translated?
        id="postalCode"
        type="number"
        label={labelsTranslations('POSTAL_CODE')}
        errorMessageDetails={invalidPostalCodeMessage}
        disabled={disabledFields?.postalCode}
      />
    </>
  );
};

export default AddressFields;
