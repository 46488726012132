import { Button, ButtonProps } from '@components/index';
import { cn } from '@lib/utils';

import styles from './BankIDButton.module.css';

type Props = Omit<ButtonProps, 'ref'>;

const BankIDButton = ({ title, className, ...rest }: Props) => {
  return (
    <Button
      variant="fourth"
      icon="bank-id"
      iconClassName={styles.bankIdIcon}
      className={cn(styles.button, className)}
      {...rest}
    >
      {title}
    </Button>
  );
};

export default BankIDButton;
